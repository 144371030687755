import { Injectable } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { DppUserProfileResource } from '../models/dpp-user-profile.resource';
import { type UserProfileModel } from '../models/user-profile.model';
import { mapInitials } from './map-initials';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class DppUserProfileMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.UserProfile;

    public map(resource: DppUserProfileResource): UserProfileModel {
        return {
            address: {
                city: resource.attributes.company.city,
                housenumber: resource.attributes.company.street_number,
                street: resource.attributes.company.street,
                zip: resource.attributes.company.zip,
            },
            identifier: {
                distributionPartnerNumber: resource.attributes.identifier.distribution_number,
                organizationId: resource.attributes.identifier.organization.id,
                bankNumber: resource.attributes.identifier.bank_account?.iban?.replace(/(.{4})/g,'$1 '),
            },
            name: {
                fullTitle: `${resource.attributes.name.firstname} ${resource.attributes.name.lastname}`,
                surname: resource.attributes.name.lastname,
                initials: mapInitials(`${resource.attributes.name.firstname} ${resource.attributes.name.lastname}`),
                forename: resource.attributes.name.firstname,
            },
            contact: {
                phone: {
                    business: resource.attributes.contact.phone,
                    mobile: resource.attributes.contact.mobile,
                },
                email: { business: resource.attributes.contact.email },
            },
            birthdate: '',
            roles: resource.attributes.roles,
            catalogs: {},
            service: {
                email: resource.attributes.service.email,
            },
        };
    }
}
